import type { Product } from '../../../modules/product/queries/types';

type DiscountType = 'PERCENTAGE' | 'ABSOLUTE';
export interface PriceDifference {
  discountType: DiscountType | null;
  difference: number;
  currencyCode?: string;
}

export const checkPriceDifference = (
  discountValue?: NonNullable<
    Product['price']['discounted']
  >['discount']['value']
): PriceDifference => {
  const noDiscount = {
    discountType: null,
    difference: 0,
  };
  if (!discountValue) {
    return noDiscount;
  }

  if ('permyriad' in discountValue) {
    return {
      discountType: 'PERCENTAGE',
      // eslint-disable-next-line no-magic-numbers
      difference: Math.floor(discountValue.permyriad / 100),
    };
  }
  if ('money' in discountValue) {
    const [discount] = discountValue.money;

    if (!discount) return noDiscount;

    return {
      discountType: 'ABSOLUTE',
      difference: -discount.centAmount,
      currencyCode: discount.currencyCode,
    };
  }

  return noDiscount;
};
